/* purgecss start ignore */

.progress {
  position: relative;
  display: flex;
  width: 100%;
}

.progress-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
}

.progress-animated {
  transition: width .3s linear;
}

.progress[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

/* purgecss end ignore */
