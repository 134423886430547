/* purgecss start ignore */

._8582b04db9-brand-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

._8582b04db9-brand-dot {
  height: .5rem;
  width: .5rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgba(113, 115, 122, var(--tw-bg-opacity));
  animation-name: yoyo;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

._8582b04db9-brand-content ._8582b04db9-brand-dot:nth-of-type(1) {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 26, 89, var(--tw-bg-opacity));
  animation-delay: .2s;
}

._8582b04db9-brand-content ._8582b04db9-brand-dot:nth-of-type(2) {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 26, 26, var(--tw-bg-opacity));
  animation-delay: .4s;
}

._8582b04db9-brand-content ._8582b04db9-brand-dot:nth-of-type(3) {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 26, 26, var(--tw-bg-opacity));
  animation-delay: .6s;
}

@keyframes yoyo {
  0% {
    transform: translateY(-50%);
  }

  25% {
    transform: translateY(50%);
  }

  50% {
    transform: translateY(50%);
  }

  75% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(-50%);
  }
}

/* purgecss end ignore */
