/* purgecss start ignore */

.icon-btn {
  position: relative;
  display: inline-flex;
  user-select: none;
  align-items: center;
  justify-content: center;
}

.icon-btn:focus {
  outline: none;
}

.icon-btn[disabled], .icon-btn.disabled {
  pointer-events: none;
  color: #1a1a1a66;
}

/* text-gray-800 */

.icon-btn.active[disabled], .icon-btn.active.disabled {
  background-color: #1a1a1a66;
}

/* .icon-btn-outline {
  border-width: 1px;
}

.icon-btn-outline:hover {
  @apply
  border-opacity-80
  opacity-80;
}

.icon-btn-outline.active {
  @apply bg-gray-400;
} */

/* purgecss end ignore */
