/* purgecss start ignore */

.icon {
  display: inline-flex;
  fill: currentColor;
}

.icon[disabled] {
  pointer-events: none;
  color: #1a1a1a66;
}

/* purgecss end ignore */
