/* purgecss start ignore */

.app-loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: var(--z-index-loading);
}

.full-screen {
  z-index: var(--z-index-loading-overlay);
}

/* purgecss end ignore */
